import { isNil } from 'ramda'

import { TableStyles } from '~/src/components/generic/DraggableTable/types'

import { getLocalTime } from '~/src/components/shared/features/helpers'
import { TWENTY_FOUR_HOURS_IN_MILLISECONDS } from '~/src/components/shared/features/constants'

import { prettyDateTimeFromObj } from '~/helpers/date'
import { endOfDay, endOfDayMoment, startOfDay, startOfDayMoment } from '~/helpers/time'

import { UNAVAILABLE_TRACTOR_STATUSES } from '~/src/components/Operational/DispatchLoadDashboard/constants'

import { LegStatus } from '~/__generated_types__/globalTypes'

import {
  DetailedLoad,
  LegWithEstimates,
  Load,
  LoadCostCenter,
  LoadWithScheduleEstimates,
  ReOrderableStop,
  Stop,
  StopWithEstimate,
  StopStatus,
  StopUpdateBase,
  Tractor,
} from '~/src/components/Operational/LoadBoardDashboard/types'

/**
 * helper function to get the date range for the current week
 */
const getWeekDateRange = (): { start: string; end: string } => {
  const today = new Date()
  const startDateTime = startOfDay(today)
  const endDateTime = endOfDay(today)
  const startDate = new Date(startDateTime.setDate(startDateTime.getDate()))
  const endDate = new Date(endDateTime.setDate(endDateTime.getDate() + 7))

  return { start: startDate.toISOString(), end: endDate.toISOString() }
}

const getFormattedTime = (time: string | undefined | null): string | undefined => {
  return time
    ? prettyDateTimeFromObj(new Date(time), false, { weekday: 'short' })
    : undefined
}

const formatTime = (time: string): string => {
  const [hours, minutes] = time.split(':')
  const hoursStr = Number(hours) === 1 ? 'hr' : 'hrs'
  const minutesStr = Number(minutes) === 1 ? 'min' : 'mins'

  if (hours === '00') {
    return `${minutes} ${minutesStr}`
  }

  if (minutes === '00') {
    return `${hours} ${hoursStr}`
  }

  return `${hours} ${hoursStr},  ${minutes} ${minutesStr}`
}

const getTrackingData = (
  load: DetailedLoad | undefined
): {
  version: string
  tmwOrder: string | undefined | null
} => {
  return {
    version: `Radius ${load?.costCenter?.radiusVersion}`,
    tmwOrder: load?.reference,
  }
}

const canStopBeAdded = (
  stops: Array<Stop>,
  stop: Stop,
  costCenter: LoadCostCenter
): Array<boolean> => {
  if (!costCenter?.id) {
    return [false, false]
  }

  let canBeAddedBefore = true
  let canBeAddedAfter = true

  if (stop.arrivalTime || stop.departureTime) {
    canBeAddedBefore = false
  }

  const stopsAheadWithArrivalOrDepartureTimes = stops
    .slice(stop.idx)
    .some((stop) => stop.arrivalTime || stop.departureTime)

  if (stopsAheadWithArrivalOrDepartureTimes) {
    canBeAddedAfter = false
  }

  const isExecutionComplete = stops.every(
    (stop) => stop.arrivalTime && stop.departureTime
  )

  if (isExecutionComplete) {
    canBeAddedBefore = false
    canBeAddedAfter = false
  }

  return [canBeAddedBefore, canBeAddedAfter]
}

const getStopStatus = (
  stop: Stop,
  stops: Array<Stop>,
  legStatus: LegStatus
): StopStatus | null => {
  if (legStatus === LegStatus.Cancelled) {
    return StopStatus.Cancelled
  }

  if (stop.arrivalTime && stop.departureTime) {
    return StopStatus.Completed
  }

  const planned_statuses = [
    LegStatus.Planned,
    LegStatus.Available,
    LegStatus.Dispatched,
  ]

  if (stop.idx === 1) {
    if (legStatus === LegStatus.Started) {
      return StopStatus.AtLocation
    } else if (planned_statuses.includes(legStatus)) {
      return StopStatus.Planned
    }
  } else {
    if (stop.arrivalTime && !stop.departureTime) {
      return StopStatus.AtLocation
    } else if (
      stops[stop.idx - 2].departureTime &&
      !stop.arrivalTime &&
      stop.departureTime
    ) {
      return StopStatus.Completed
    } else if (
      stops[stop.idx - 2].departureTime &&
      !stop.arrivalTime &&
      !stop.departureTime
    ) {
      return StopStatus.InTransit
    } else if (!stops[stop.idx - 2].departureTime) {
      return StopStatus.Planned
    }
  }

  return null
}

const getTableStyles = (errorRow: Stop | null): TableStyles<Stop> => {
  return {
    rows: {
      style:
        'h-[6.5rem] items-center text-label gap-x-4 border-b border-x border-gray-75 group hover:bg-gray-25',
      getHighlightOnSelectStyle: (row) => {
        if (row.id === errorRow?.id) {
          return 'border-red-500 border'
        }

        return ''
      },
    },
    header: {
      style:
        'h-8 justify-start items-center inline-flex text-4xs uppercase bg-gray-50/50 text-gray-800 gap-x-4 border border-gray-75',
    },
    headerCell: {
      style: 'px-0',
    },
    cells: {
      style: 'px-0',
    },
  }
}

const getStopsWithReorderStatus = (leg: LegWithEstimates): Array<ReOrderableStop> => {
  return leg.stops.map((stop) => {
    const status = getStopStatus(stop, leg.stops, leg.status)

    return {
      ...stop,
      legId: leg.id,
      isReorderable: [StopStatus.Planned, StopStatus.InTransit].includes(
        status as StopStatus
      ),
    }
  })
}

const isLegPlannedStartTimeEditable = (
  load: DetailedLoad | undefined,
  legIdx: number,
  isRV3?: boolean | null
) => {
  if (!load) return false

  const currentLeg = load.legs.at(legIdx - 1)

  if (!currentLeg) return false

  const isLastLeg = load.legs.length === currentLeg.idx
  const isPreviousLegCompleted =
    currentLeg.idx === 1 || load.legs[currentLeg.idx - 2].status === LegStatus.Completed
  const isRV2 = isLastLeg && isPreviousLegCompleted

  return (
    [LegStatus.Available, LegStatus.Planned, LegStatus.Dispatched].includes(
      currentLeg.status
    ) &&
    (isRV3 || isRV2)
  )
}

const transformData = (load: DetailedLoad) => {
  let idx = 1

  return {
    ...load,
    legs: load.legs.map((leg) => {
      return {
        ...leg,
        stops: leg.stops.map((stop) => {
          return {
            ...stop,
            globalIdx: idx++,
          }
        }),
      }
    }),
  }
}

const validateAppointmentTime = (
  appointmentStart: string | undefined | null,
  appointmentEnd: string | undefined | null
): { message: string | undefined } => {
  if (isNil(appointmentStart) || isNil(appointmentEnd)) {
    return { message: 'Provide complete appointment start and end times.' }
  }

  const appTimeStart = new Date(appointmentStart).toISOString()
  const appTimeEnd = new Date(appointmentEnd).toISOString()

  let message: string | undefined = undefined

  if (appTimeStart > appTimeEnd) {
    message = 'Appointment start time can’t be after end time'
  }

  return { message }
}

const getSuggestedAppointmentWindow = (
  load: DetailedLoad | undefined,
  timeZoneName: string | undefined
): {
  suggestedStartTime: {
    time: string | undefined
    timeZone: string | undefined
  }
  suggestedEndTime: {
    time: string | undefined
    timeZone: string | undefined
  }
} => {
  const firstLeg = load?.legs[0]
  const lastLeg = load?.legs.at(-1)

  const lastStopDeparture =
    lastLeg?.stops.at(-1)?.departureTime ??
    lastLeg?.stops.at(-1)?.estimateData?.estimatedDepartureTime

  const localTimeAppointmentStart = getLocalTime(
    firstLeg?.plannedStartTime,
    timeZoneName
  )
  const localTimeAppointmentEnd = getLocalTime(
    lastLeg?.plannedEndTime ?? lastStopDeparture,
    timeZoneName
  )

  const appointmentStart =
    localTimeAppointmentStart && startOfDayMoment(localTimeAppointmentStart)
  const appointmentEnd =
    localTimeAppointmentEnd && endOfDayMoment(localTimeAppointmentEnd)

  return {
    suggestedStartTime: {
      time: appointmentStart,
      timeZone: timeZoneName,
    },
    suggestedEndTime: {
      time: appointmentEnd,
      timeZone: timeZoneName,
    },
  }
}

const isAppointmentTimeChanged = (stopUpdate: StopUpdateBase, stop: Stop) => {
  const isAppointmentStartChanged =
    stopUpdate.appointmentStart &&
    (!stop.appointmentStart || stopUpdate.appointmentStart !== stop.appointmentStart)

  const isAppointmentEndChanged =
    stopUpdate.appointmentEnd &&
    (!stop.appointmentEnd || stopUpdate.appointmentEnd !== stop.appointmentEnd)

  return isAppointmentStartChanged || isAppointmentEndChanged
}

/**
 * Get in progress or next stop which is the stop that has either
 * estimated arrival time or estimated departure time.  Completed stops
 * do not have estimated times associated with them.
 */
const getCurrentOrNextStop = (
  load: DetailedLoad | LoadWithScheduleEstimates | undefined
): Stop | StopWithEstimate | undefined => {
  const allStops = load?.legs.flatMap((leg) => leg.stops)

  return allStops?.find(
    (stop) =>
      stop.estimateData?.estimatedArrivalTime ||
      stop.estimateData?.estimatedDepartureTime
  )
}

/**
 * Determine whether the provided expiration date is before the planned end date.
 */
const isExpirationDateBeforePlannedEnd = (
  expirationDateString: string,
  plannedEndDateString: string
): boolean => {
  return new Date(expirationDateString) < new Date(plannedEndDateString)
}

const extractLocationInfo = (legs: Array<LegWithEstimates>) => {
  const firstLeg = legs?.[0]
  const lastLeg = legs?.[legs.length - 1]
  const origin = firstLeg?.origin
  const destination = firstLeg?.destination

  const startTimeZoneInfo = firstLeg?.stops?.[0]?.location
  const endTimeZoneInfo = lastLeg?.stops?.at(-1)?.location

  return {
    origin,
    destination,
    startTimeZoneName: startTimeZoneInfo?.timeZoneName,
    startTimeZoneCode: startTimeZoneInfo?.timeZoneCode,
    endTimeZoneName: endTimeZoneInfo?.timeZoneName,
    endTimeZoneCode: endTimeZoneInfo?.timeZoneCode,
  }
}

const getRemainingTime = (scheduledTime: string): number => {
  const lastStopTime = new Date(scheduledTime)

  // Calculate 24 hours after scheduled time
  const lastStopPlus24Hours = new Date(
    lastStopTime.getTime() + TWENTY_FOUR_HOURS_IN_MILLISECONDS
  )

  const currentTime = new Date()

  if (currentTime < lastStopTime) {
    const timeUntilScheduled = lastStopTime.getTime() - currentTime.getTime()

    return Math.floor(timeUntilScheduled / 1000)
  }

  const differenceInMilliseconds = lastStopPlus24Hours.getTime() - currentTime.getTime()

  if (differenceInMilliseconds <= 0) {
    return 0
  }

  return Math.floor(differenceInMilliseconds / 1000)
}

const sortTractorsByAvailability = (a: Tractor, b: Tractor) => {
  if (
    UNAVAILABLE_TRACTOR_STATUSES.includes(a.status) &&
    !UNAVAILABLE_TRACTOR_STATUSES.includes(b.status)
  ) {
    return 1
  }
  if (
    !UNAVAILABLE_TRACTOR_STATUSES.includes(a.status) &&
    UNAVAILABLE_TRACTOR_STATUSES.includes(b.status)
  ) {
    return -1
  }

  return 0
}

const getLegsWithoutDriverIndices = (
  load: DetailedLoad | Load,
  isRV3: boolean
): Array<number> => {
  const legs = isRV3 ? [load.legs[0]] : load.legs

  return legs.filter((leg) => !leg.primaryDriver).map((leg) => leg.idx)
}

export {
  canStopBeAdded,
  formatTime,
  getFormattedTime,
  getStopStatus,
  getStopsWithReorderStatus,
  getTableStyles,
  getWeekDateRange,
  isLegPlannedStartTimeEditable,
  transformData,
  validateAppointmentTime,
  getSuggestedAppointmentWindow,
  isAppointmentTimeChanged,
  getCurrentOrNextStop,
  getTrackingData,
  isExpirationDateBeforePlannedEnd,
  extractLocationInfo,
  getRemainingTime,
  sortTractorsByAvailability,
  getLegsWithoutDriverIndices,
}
