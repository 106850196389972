import { ReactNode, MouseEvent, useRef, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import Spinner from '~/src/components/generic/Spinner'

type Props<T> = {
  results: Array<T>
  maxNumOptions: number
  highlightedRowIndex: number
  hasFocus: boolean
  searchString: string
  resultsClassName?: string
  isLoading?: boolean
  onSelect: (value: T) => void
  renderOption: (value: T) => ReactNode
  setHighlightedRowIndex: (index: number) => void
}

const Results = <T,>({
  searchString,
  hasFocus,
  results,
  maxNumOptions,
  highlightedRowIndex,
  resultsClassName,
  isLoading,
  renderOption,
  onSelect,
  setHighlightedRowIndex,
}: Props<T>) => {
  const optionEls = useRef<Array<HTMLDivElement | null>>([])
  const optionsContainerEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    optionEls.current[highlightedRowIndex]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }, [highlightedRowIndex])

  useEffect(() => {
    if (optionsContainerEl.current && optionsContainerEl.current.scrollTop !== 0) {
      optionsContainerEl.current.scrollTop = 0
    }
    setHighlightedRowIndex(-1)
  }, [searchString, setHighlightedRowIndex])

  const showResults = hasFocus && results.length > 0 && !isLoading

  const loadingState = isLoading && hasFocus && (
    <div className="flex h-56 items-center justify-center">
      <Spinner />
    </div>
  )

  const handleMouseDown = ({
    event,
    option,
  }: {
    event: MouseEvent<HTMLDivElement>
    option: T
  }) => {
    event.preventDefault()
    onSelect(option)
  }

  return (
    <div
      className={twMerge(
        'absolute z-50 mt-0.5 flex w-full min-w-[100px] flex-col overflow-hidden rounded-b-md bg-white shadow-xl',
        resultsClassName
      )}
    >
      <div
        ref={optionsContainerEl}
        className="max-h-60 overflow-y-auto"
        data-testid="search-autocomplete-result"
      >
        {loadingState}
        {showResults &&
          results.slice(0, maxNumOptions).map(
            (option: T, index): ReactNode => (
              <div
                key={index}
                ref={(el) => {
                  optionEls.current[index] = el
                }}
                className={twMerge(
                  'w-full cursor-pointer px-2 py-1 text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                  index === highlightedRowIndex && 'bg-gray-50 text-gray-900'
                )}
                onMouseDown={() => {
                  onSelect(option)
                }}
                onClick={(event) => handleMouseDown({ event, option })}
                data-testid="search-list-item"
              >
                {renderOption(option)}
              </div>
            )
          )}
      </div>
    </div>
  )
}

export default Results
